import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const PaginationSelection = (props) => {
  const { maxPage = null, setCurrentPage = () => {}, currentPage = null } = props;

  const pageArray = Array.from(Array(maxPage), (e, i) => i + 1);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Popover as="div" className="relative flex justify-center items-center">
      {({ open }) => (
        <>
          <div className="flex">
            <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-200 dark:hover:text-white">
              Page {`${currentPage} / ${maxPage}`}
              <ChevronDownIcon
                className={`${open ? '-rotate-180' : 'rotate-0'} transition -mr-1 ml-1 h-5 w-5`}
                aria-hidden="true"
              />
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Popover.Panel className="absolute overflow-y-auto max-h-40  -bottom-14 z-40 mt-2 w-20 rounded-md p-1 flex flex-col items-end bg-white dark:bg-gray-800 dark:border dark:border-gray-600 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none dark:shadow-gray-700 dark:shadow-md">
              <div className="w-full">
                {pageArray.map((option, index) => (
                  <Popover.Button
                    key={`optin_${index}`}
                    onClick={() => setCurrentPage(option)}
                    className={classNames(
                      option === currentPage
                        ? `bg-gray-200 dark:bg-teal-400/10  dark:ring-1 dark:ring-inset dark:text-teal-300 dark:ring-teal-300`
                        : `hover:bg-gray-100 dark:bg-teal-700/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`,
                      index === 0 && 'rounded-t-md',
                      index === maxPage && 'rounded-b-md',
                      ' cursor-pointer w-full flex justify-center items-center overflow-hidden text-sm font-medium transition py-1 px-2'
                    )}>
                    {option}
                  </Popover.Button>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PaginationSelection;
