import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';
import moment from 'moment';
import { baseFrontUrl } from '@root/config';
import { XMarkIcon } from '@heroicons/react/24/outline';

function FeuillesPage() {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const filter = employeeId
    ? {
        heures: {
          employee: {
            id: {
              $eq: employeeId
            }
          }
        }
      }
    : {};

  const { data: employee } = useQuery(
    ['employees', employeeId],
    () => queries.get(`/api/employees`, employeeId, { populate: '*' }).then((res) => res.data),
    { retry: false, enabled: !(typeof employeeId === 'undefined' || employeeId === null) }
  );

  const { data: tableData, isLoading: isLoading } = useQuery(['sheets', filter], () =>
    queries
      .getAll(`/api/sheets`, {
        populate: ['heures.employee'],
        filters: filter
      })
      .then((res) => res.data)
  );

  return (
    <Section
      title="Feuilles"
      description="Liste des Feuilles"
      onClick={() => navigate('/edit/sheet')}>
      {employeeId && (
        <div className="flex flex-wrap items-center text-gray-900 dark:text-gray-100 pt-4 gap-4">
          {`Feuille${tableData?.length > 1 ? 's' : ''} de l'employé : ${employee?.Nom} ${
            employee?.Prenom
          }`}
          <Link to={'/sheet'}>
            <XMarkIcon className="h-6 w-6" />
          </Link>
        </div>
      )}
      <Table
        data={tableData?.map((o) => ({
          id: o?.id,
          name: o?.Nom,
          begin: o?.Debut,
          end: o?.Fin,
          heure: o?.heures?.reduce(
            (a, b) =>
              employeeId
                ? a + (b?.employee?.id === Number(employeeId) ? b?.heure : 0)
                : a + b?.heure,
            0
          ),
          year: moment(o?.Debut).format('YYYY'),
          link: (
            <a
              className="text-orange-600"
              href={`${baseFrontUrl}/?s=${o?.id}`}
              target="_blank"
              rel="noreferrer">
              Ouvrir le lien
            </a>
          )
        }))}
        paginate={25}
        groupBy={'year'}
        groupBySorted={'DSC'}
        onClick={(_, o) => navigate(`/edit/sheet/${o?.id}`)}
        labels={['Id', 'Nom', 'Debut', 'Fin', 'Heures', 'Lien TimeTracker']}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default FeuillesPage;
