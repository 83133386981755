import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';

function HeuresPage() {
  const navigate = useNavigate();

  const capitalize = (val) => val?.[0]?.toUpperCase() + val?.slice(1, val?.length)?.toLowerCase();

  const { data: tableData, isLoading: isLoading } = useQuery(['heures'], () =>
    queries.getAll(`/api/heures`, { populate: '*' }).then((res) => res.data)
  );

  const sortedData = tableData?.sort((a, b) => a.id - b.id).reverse();

  return (
    <Section
      title="Heures"
      description="Liste des chantiers"
      onClick={() => navigate('/edit/heure')}>
      <Table
        data={sortedData?.map((o) => ({
          id: o?.id,
          name: `${capitalize(o?.employee?.Nom)} ${capitalize(o?.employee?.Prenom)}`,
          chantier: o?.chantier?.Nom || 'Chantier non affecté',
          chantierBis: o?.chantier?.Nom || 'Chantier non affecté',
          heures: o?.heure,
          jour: o?.jour,
          sheet: o?.feuille?.Nom,
          comment: o?.comment ? o?.comment : 'Pas de commentaire'
        }))}
        onClick={(_, o) => navigate(`/edit/heure/${o?.id}`)}
        labels={['Id', 'Nom', 'Chantier', 'Heure', 'Jour', 'Feuilles', 'Comment']}
        className={'mt-2 sm:mt-5'}
        groupBy={'chantierBis'}
        paginate={25}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default HeuresPage;
