import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';
import moment from 'moment';

const EmployeesPage = () => {
  const navigate = useNavigate();

  const capitalize = (val) => val[0].toUpperCase() + val.slice(1, val.length).toLowerCase();

  const { data: tableData, isLoading: isLoading } = useQuery(['employees'], () =>
    queries.getAll(`/api/employees`, { populate: '*' }).then((res) => res.data)
  );

  const sortedData = tableData?.sort((a, b) => a.id - b.id).reverse();

  return (
    <Section
      title="Employés"
      description="Liste des employés"
      onClick={() => navigate('/edit/employee')}>
      <Table
        data={sortedData?.map((o) => ({
          id: o?.id,
          idReport: o?.idReport,
          name: (
            <Link to={`/sheet/${o?.id}`}>
              {capitalize(o?.Nom)} {capitalize(o?.Prenom)}
            </Link>
          ),
          agence: o?.Agence,
          heure: o?.heures?.reduce((a, b) => a + b?.heure, 0),
          debut: o?.Debut ? moment(o?.Debut).format('DD/MM/YYYY') : '',
          fin: o?.Fin ? moment(o?.Fin).format('DD/MM/YYYY') : ''
        }))}
        paginate={25}
        onClick={(_, o) => navigate(`/edit/employee/${o?.id}`)}
        labels={['Id', 'Id Report', 'Nom', 'Agence', 'Heures', 'Date de début', 'Date de fin']}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
};

export default EmployeesPage;
