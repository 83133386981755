import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, Cog8ToothIcon, PowerIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Avatar, DarkModeButton } from '@components';
import { Menu } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SideBar = (props) => {
  const {
    avatar = '',
    tabs = [],
    logo = '',
    logoLink = '/',
    classNameLogo = '',
    userActions = [],
    title = '',
    children = null,
    username = ''
  } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-600 dark:bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:bg-slate-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:focus:ring-black"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link to={logoLink} className="h-full flex justify-center items-center">
                      <img src={logo} className={'h-8 ' + classNameLogo} />
                      <h2 className="ml-3 text-lg font-medium">{title}</h2>
                    </Link>
                  </div>
                  <nav className="mt-5 space-y-1 px-2 transition">
                    {tabs.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.active
                            ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-white'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-slate-700 dark:hover:text-white',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md transition'
                        )}>
                        {item.icon}
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  <a href="#" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <Avatar>{avatar}</Avatar>
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                          {username}
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 dark:border-gray-800 bg-white dark:bg-slate-900">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <div className="flex items-center">
                <Link to={logoLink} className="h-full flex justify-center items-center">
                  <img src={logo} className={'h-8 ' + classNameLogo} />
                  <h2 className="ml-3 text-lg font-medium sm:hidden block">{title}</h2>
                </Link>
              </div>
            </div>
            <nav className="mt-5 flex-1 space-y-1 bg-white dark:bg-slate-900 px-2 transition">
              {tabs.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.active
                      ? 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-slate-700 dark:hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}>
                  {item.icon && item.icon}
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
          <div className="flex justify-center items-center flex-shrink-0 border-t border-gray-200 dark:border-gray-800 p-4 px-6">
            <a href="#" className="group block w-full flex-shrink-0">
              <div className="flex items-center">
                <div>
                  <Avatar>{avatar}</Avatar>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                    {username}
                  </p>
                  <p className="text-xs font-medium text-gray-500  group-hover:text-gray-700 dark:group-hover:text-gray-200">
                    View profile
                  </p>
                </div>
              </div>
            </a>
            <div>
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button
                    className={`bg-white dark:bg-slate-900 dark:text-gray-200 rounded-full flex text-sm focus:outline-none `}>
                    <span className="sr-only">Open user menu</span>

                    <Cog8ToothIcon className="h-6 w-6" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute bottom-7 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                    <div className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer justify-end items-center gap-2 dark:hover:bg-slate-700">
                      <DarkModeButton labelDisplay={true} />
                    </div>
                    {userActions?.map((elem, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <a
                            href="#"
                            key={index}
                            onClick={(e) => elem?.fnc(e)}
                            className={classNames(
                              active ? 'bg-gray-100 dark:bg-slate-700' : '',
                              'flex justify-end items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                            )}>
                            {elem?.title}
                            <PowerIcon className="h-4 w-4" />
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-10 bg-gray-100 dark:bg-slate-900 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 dark:text-gray-200 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 overflow-x-auto pb-5">
            {/* Replace with your content */}
            {children}
            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SideBar;
