import React, { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import _ from 'lodash';
import { InputText } from '@components';
import CheckBox from '../../CheckBox';
import { Popover } from '@headlessui/react';

const Filter = (props) => {
  const {
    filterKey = '',
    data = [],
    selectedData = [],
    setSelectedData = () => {},
    sortedData = [],
    handleSortedData = () => {}
  } = props;

  const [search, setSearch] = useState('');
  const [sortOption] = useState('');
  const [checked, setChecked] = useState(false);

  const toggleSelectedData = (value, checked) =>
    checked
      ? setSelectedData([...selectedData, value])
      : setSelectedData(selectedData?.filter((v) => v != value));

  const toggleSelectedAllData = (checked) =>
    checked ? setSelectedData(avoidDoublon) : setSelectedData([]);

  // Handle the sorted in the component
  const dataSorted = _.orderBy(data, filterKey, sortOption);

  // Enable simple filter
  const filteredData = dataSorted?.filter((o) =>
    o?.[filterKey]
      ?.toString()
      ?.toLowerCase()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      .includes(search?.toLowerCase())
  );

  const avoidDoublon = _.uniq(filteredData.map((o) => o?.[filterKey]));

  const handleCloseModal = (val) => {
    handleSortedData(filterKey, val);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <>
      <div className="relative mb-2">
        <InputText
          label="Search"
          placeholder="Search..."
          autoFocus
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="relative mb-2">
        <label
          htmlFor="Search"
          className={'block text-sm font-medium text-gray-700 dark:text-gray-200'}>
          Order :
        </label>
        <span className="isolate inline-flex rounded-md w-full py-1">
          <Popover.Button
            className={classNames(
              sortedData?.[filterKey] === 'asc'
                ? `bg-gray-200 dark:bg-teal-400/10  dark:ring-1 dark:ring-inset dark:text-teal-300 dark:ring-teal-300`
                : ` border dark:border-none hover:bg-gray-100 dark:bg-teal-700/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`,
              ' rounded-l-full cursor-pointer w-full flex justify-center items-center overflow-hidden text-sm font-medium transition py-1 px-2'
            )}
            onClick={() => handleCloseModal('asc')}>
            <ArrowUpIcon className="w-3 h-3" />
          </Popover.Button>
          <Popover.Button
            className={classNames(
              sortedData?.[filterKey] === 'desc'
                ? `bg-gray-200 dark:bg-teal-400/10  dark:ring-1 dark:ring-inset dark:text-teal-300 dark:ring-teal-300`
                : ` border dark:border-none hover:bg-gray-100 dark:bg-teal-700/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`,
              ' rounded-r-full cursor-pointer w-full flex justify-center items-center overflow-hidden text-sm font-medium transition py-1 px-2'
            )}
            onClick={() => handleCloseModal('desc')}>
            <ArrowDownIcon className="w-3 h-3" />
          </Popover.Button>
        </span>
      </div>
      <div className="flex items-center max-h-10 cursor-pointer px-1">
        <CheckBox
          label={'All'}
          name="All"
          indeterminateVal={selectedData.length > 0 && selectedData.length < avoidDoublon.length}
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          onClick={(e) => toggleSelectedAllData(e.target.checked)}
        />
      </div>
      <div className="max-h-24 overflow-y-auto px-1 flex flex-col gap-1">
        {avoidDoublon?.map((op, idx) => (
          <CheckBox
            key={`filterbox_${idx}`}
            id={`filter-${op}`}
            label={op}
            name={`filter-${op}`}
            checked={selectedData?.includes(op)}
            onChange={(e) => toggleSelectedData(op, e.target.checked)}
          />
        ))}
      </div>
    </>
  );
};

export default Filter;
