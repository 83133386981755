import { XCircleIcon } from '@heroicons/react/24/solid';

function Alert(props) {
  const {
    color = 'red',
    icon: Icon = XCircleIcon,
    className = '',
    children = null,
    ...rest
  } = props;

  return (
    <div className={`rounded-md bg-${color}-50 p-4 ${className}`} {...rest}>
      <div className="flex">
        {Icon && (
          <div className="flex-shrink-0">
            <Icon className={`h-5 w-5 text-${color}-500`} aria-hidden="true" />
          </div>
        )}
        <div className={Icon && 'ml-3'}>
          <p className={`text-sm text-${color}-800 my-0`}>{children}</p>
        </div>
      </div>
    </div>
  );
}

export default Alert;
