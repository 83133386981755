import { Fragment, isValidElement } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import FilterNotification from '../components/ClearFilterButton';
import Filter from '../components/Filter';

const FilterBox = (props) => {
  const {
    label = '',
    filterKey = '',
    data = [],
    setOpen = () => {},
    selectedData = [],
    setSelectedData = () => {},
    handleSortedData = () => {},
    sortedData = [],
    lengthOfDataShown = null
  } = props;

  return !isValidElement(data[0]?.[filterKey]) ? (
    <Popover as="th" className="relative p-3 border-r dark:border-gray-800" scope="col">
      {({ open }) => (
        <>
          <Popover.Button
            className={`inline-flex items-center justify-center focus:outline-none hover:text-gray-900 dark:hover:text-white text-xs ${
              open ? 'dark:text-white text-black' : 'dark:text-gray-200 text-gray-700'
            } `}>
            <span className="uppercase">{label}</span>
            <ChevronDownIcon
              className={`${open ? '-rotate-180' : 'rotate-0'} mx-1 h-5 w-5 transition`}
              aria-hidden="true"
            />
            {selectedData.length > 0 ? (
              <FilterNotification label={selectedData.length} onClick={() => setSelectedData([])} />
            ) : null}
            {sortedData?.[filterKey] ? (
              <FilterNotification
                label={
                  sortedData?.[filterKey].charAt(0).toUpperCase() + sortedData?.[filterKey].slice(1)
                }
                onClick={() => handleSortedData(filterKey, sortedData?.[filterKey])}
              />
            ) : null}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Popover.Panel
              className={`${
                lengthOfDataShown > 5 ? 'absolute' : 'fixed'
              } max-h-[300px] min-w-[150px] z-40 mt-2 origin-top-right rounded-md bg-white dark:bg-gray-900 dark:border dark:border-gray-600 px-4 py-2 shadow-2xl ring-1 ring-zinc-900 ring-opacity-5 focus:outline-none overflow-hidden transition`}>
              <Filter
                filterKey={filterKey}
                data={data}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                sortedData={sortedData}
                open={open}
                setOpen={setOpen}
                handleSortedData={handleSortedData}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  ) : (
    <th className={'relative pl-2 border-r dark:border-gray-800'} scope="col">
      <div className="cursor-default group inline-flex items-center justify-center text-xs text-gray-700 dark:text-gray-200 focus:outline-none">
        <span className="uppercase">{label}</span>
      </div>
    </th>
  );
};

export default FilterBox;
