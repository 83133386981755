import React from 'react';

import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import FilterNotification from '../components/ClearFilterButton';

import Filter from '../components/Filter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FilterBoxResponsive = (props) => {
  const {
    label = '',
    filterKey = '',
    data = [],
    selectedData = [],
    setSelectedData = () => {},
    setOpen = () => {},
    open = null,
    handleSortedData = () => {},
    sortedData = []
  } = props;

  return (
    <>
      <h3 className="-mx-2 -my-3 flow-root">
        <Popover.Button
          className="flex w-full items-center justify-between bg-white dark:bg-gray-900 px-2 py-3 text-sm text-gray-400"
          onClick={() => setOpen(!open)}>
          <span className="font-medium text-gray-900 dark:text-gray-200 uppercase">{label}</span>
          <span className="ml-6 flex gap-1 items-center">
            {selectedData.length > 0 ? (
              <FilterNotification label={selectedData.length} onClick={() => setSelectedData([])} />
            ) : null}
            {sortedData?.[filterKey] ? (
              <FilterNotification
                label={
                  sortedData?.[filterKey].charAt(0).toUpperCase() + sortedData?.[filterKey].slice(1)
                }
                onClick={() => handleSortedData(filterKey, sortedData?.[filterKey])}
              />
            ) : null}
            <ChevronDownIcon
              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transition')}
              aria-hidden="true"
            />
          </span>
        </Popover.Button>
      </h3>
      <Popover.Panel className="pt-6">
        <Filter
          filterKey={filterKey}
          data={data}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          handleSortedData={handleSortedData}
          sortedData={sortedData}
          open={open}
          setOpen={setOpen}
        />
      </Popover.Panel>
    </>
  );
};

export default FilterBoxResponsive;
