import { motion } from 'framer-motion';
const Section = (props) => {
  const { title = '', description = '', children = null, onClick = () => {} } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}>
      <div className="px-4 sm:px-6 lg:px-8 mt-4 sm:mt-6 lg:mt-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">{title}</h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">{description}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full bg-zinc-900 py-2 px-3 text-white hover:bg-zinc-700 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300"
              onClick={() => onClick()}>
              Ajouter {title}
            </button>
          </div>
        </div>
        {children}
      </div>
    </motion.div>
  );
};

export default Section;
