import Sidebar from './SideBar';
import SimpleNavBar from './SimpleNavBar';

const Header = (props) => {
  const { style = 'SimpleNavBar', ...rest } = props;

  if (style === 'SimpleNavBar') return <SimpleNavBar {...rest} />;
  else if (style === 'SideBar') return <Sidebar {...rest} />;
};

export default Header;
