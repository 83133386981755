const InputText = (props) => {
  const {
    label = '',
    className = '',
    classNameInput = '',
    classNameLabel = '',
    name = '',
    ...rest // To handle all unhandled props so far
  } = props;

  return (
    <div className={'relative ' + className}>
      <label
        htmlFor={name}
        className={'block text-sm font-medium text-gray-700 dark:text-gray-200 ' + classNameLabel}>
        {label}
      </label>
      <input
        type="text"
        className={`mt-1 block w-full bg-gray-100 dark:bg-gray-800 dark:text-gray-50 shadow-sm focus:ring-emarald-500 focus:border-emerald-500 dark:focus:ring-emerald-700 dark:focus:border-emerald-700 sm:text-sm border-gray-300 dark:border-gray-700 rounded-md ${classNameInput}`}
        name={name}
        id={name}
        {...rest}
      />
    </div>
  );
};

export default InputText;
