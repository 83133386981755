import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const Pagination = (props) => {
  const { range = 5, maxPage = 10, currentPage = 1, setCurrentPage = () => {} } = props;

  // const [roundedLeft, setRoundedLeft] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // Making an array from the number of page
  const valueMin = Math.min(
    Math.max(currentPage - Math.floor(range / 2), 1),
    Math.max(maxPage - range + 1, 1)
  );

  const pageNumbers = new Array(Math.min(range, maxPage))
    .fill(0)
    .map((_, index) => valueMin + index);

  // Rendering the array in jsx, if the the page is in the array of page
  const renderPagesNumbers = pageNumbers?.map((number) => (
    <a
      key={number}
      onClick={() => setCurrentPage(number)}
      className={classNames(
        currentPage === number
          ? `text-white bg-zinc-900 dark:bg-teal-400/10  dark:ring-1 dark:ring-inset ring-0 dark:text-teal-300 dark:ring-teal-300 `
          : `text-white bg-zinc-700 hover:bg-zinc-900 dark:bg-teal-700/10 dark:text-teal-400  dark:ring-1 ring-gray-500 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`,
        number === 1 && currentPage == 1 && 'rounded-l-full',
        number === maxPage && currentPage == maxPage && 'rounded-r-full',
        ' border border-gray-500/60 dark:border-none cursor-pointer flex justify-center items-center overflow-hidden text-sm font-medium transition py-1 px-2 w-10'
      )}>
      {number}
    </a>
  ));

  const renderNavButton = (dir) => {
    return (
      <a
        className={`cursor-pointer flex gap-0.5 justify-center items-center overflow-hidden text-sm font-medium transition ${
          dir ? 'rounded-l-full' : 'rounded-r-full'
        } border border-gray-500/60 dark:border-none py-1 px-2  text-white bg-zinc-700 hover:bg-zinc-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 ring-gray-500 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`}
        onClick={() => setCurrentPage(dir ? currentPage - 1 : currentPage + 1)}>
        {dir ? <ChevronLeftIcon className="h-6 w-6" /> : <ChevronRightIcon className="h-6 w-6" />}
      </a>
    );
  };

  return (
    <div className="flex z-10 items-center justify-center">
      <div className="flex flex-1 items-center justify-center">
        <div>
          <nav className="isolate inline-flex rounded-md transition" aria-label="Pagination">
            {currentPage > 1 && renderNavButton(true)}
            {renderPagesNumbers}
            {currentPage < maxPage && renderNavButton(false)}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
