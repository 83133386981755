import React, { useState } from 'react';
import { Button, Modal, DropDown } from '@components';

const ExportModal = (props) => {
  const { title = '', url = '', data = [], idSheet = '', setIdSheet = () => {} } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full  dark:bg-gray-900 py-1 px-3 focus:ring-teal-400/20 bg-gray-700 hover:bg-gray-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300">
        {title}
      </Button>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="border-b dark:border-gray-500 p-2 dark:text-gray-200 text-xl ">
          <h1> {title}</h1>
        </div>
        <div className="p-2">
          <DropDown
            label="Semaine :"
            selectName="Choisissez votre semaine."
            data={data?.map((o) => ({
              label: o.Nom,
              value: o.id
            }))}
            isControlled
            value={idSheet}
            onChange={(e) => setIdSheet(e.target.value)}
          />
        </div>
        <div className="p-2 text-right">
          <Button onClick={() => window.open(url, '_blank')}>Télécharger</Button>
        </div>
      </Modal>
    </>
  );
};

export default ExportModal;
