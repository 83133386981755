import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import { Form } from '@layouts';
import { Button, Alert } from '@components';
import { motion } from 'framer-motion';
import queries from '@queries';

const EditForm = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pageName, id } = useParams();

  const mutationCreate = useMutation((data) => queries?.post(`/api/${pageName}s`, data));
  const mutationUpdate = useMutation((data) => queries?.put(`/api/${pageName}s`, data));
  const mutationDelete = useMutation((data) => queries?.delete(`/api/${pageName}s`, data));
  const mutation = id ? mutationUpdate : mutationCreate;

  if (mutationDelete?.isSuccess || mutation?.isSuccess) {
    queryClient.invalidateQueries(pageName);
    navigate(`/${pageName}`);
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: window.innerWidth }}>
      <div className="md:grid md:grid-cols-3 md:gap-6 pt-6">
        <div className="md:col-span-1">
          <div className="md:col-span-1">
            <ArrowLeftCircleIcon
              className="w-10 h-10 text-gray-500 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <div className="ml-12">
              <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
                {props?.title}
              </h3>
              <p className="mt-1 text-sm text-gray-600">{props?.description}</p>
            </div>
          </div>
        </div>

        <div className="mt-5 md:col-span-2 md:mt-0">
          <Form mutation={mutation}>
            <div className="shadow rounded-md bg-white  dark:bg-gray-800/50 dark:shadow-gray-700 border dark:border-gray-700">
              <div className="space-y-6 px-4 py-5 sm:p-4">
                <div className="grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2 space-y-4">
                    {(mutation?.isError || mutationDelete?.isError) && (
                      <Alert color="red" className="mb-4 md:col-span-2">
                        Une erreur s&apos;est produite lors de la modification des informations{' '}
                        <br />
                        Veuillez réessayer.
                      </Alert>
                    )}
                    {props.children}
                  </div>
                  <div className={`flex px-4 pt-5 justify-${id ? 'between' : 'end'} sm:px-6`}>
                    {id && (
                      <Button
                        color="red"
                        type="button"
                        isWrong={mutationDelete?.isError}
                        isLoading={mutationDelete?.isLoading}
                        onClick={() => mutationDelete?.mutate([{ id }])}>
                        Supprimer
                      </Button>
                    )}
                    <Button
                      type="submit"
                      isWrong={mutation?.isError}
                      isLoading={mutation?.isLoading}
                      onClick={() => mutation}>
                      {id ? 'Modifier' : 'Enregistrer'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default EditForm;
