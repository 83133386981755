import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import logo from '@assets/small.png';
import queries from '@queries';

import { Header } from '@layouts';

const capitalize = ([first = '', ...rest]) => [first.toUpperCase(), ...rest].join('');
const initialsFromEmail = (email) =>
  email
    ?.split('@')?.[0]
    ?.split('.')
    ?.map((word) => word?.toUpperCase()?.[0])
    ?.join('');

function Panel(props) {
  const params = useParams();
  const { links = [], paramKey = 'pageName' } = props;

  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), { retry: false });

  return (
    <div className={`min-h-screen h-full bg-gray-100 dark:bg-gray-900 transition overflow-hidden`}>
      <Header
        tabs={links?.map((value) => ({
          name: capitalize(value?.name),
          active: value?.pageName === params?.[paramKey],
          href: `/${value?.pageName}`,
          icon: value?.icon
        }))}
        logoLink="/home"
        userActions={[
          {
            title: 'Déconnexion',
            fnc: () => {
              window.location.replace('/');
              sessionStorage.removeItem('token');
            }
          }
        ]}
        logo={logo}
        avatar={initialsFromEmail(me?.data?.email)}
        username={me?.data?.username}>
        {links?.find((o) => o?.pageName == params?.[paramKey])?.render}
      </Header>
    </div>
  );
}

export default Panel;
