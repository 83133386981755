import { Fragment, useState, isValidElement } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { XMarkIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import FilterBox from '../wrapper/FilterBox';
import FilterBoxResponsive from '../wrapper/FilterBoxResponsive';

const FilterBoxLayout = (props) => {
  const {
    labels = [],
    keys = [],
    hideEdit = false,
    data = [],
    selectedData = {},
    setSelectedData = () => {},
    updateSortedData = () => {},
    sortedData = [],
    totalElement = null,
    lengthOfDataShown = null
  } = props;

  const [open, setOpen] = useState(false);

  // Handle updateSort
  function handleSortedData(filterKey, val) {
    const value = val !== sortedData?.[filterKey] ? val : '';
    updateSortedData(filterKey, value);
  }

  return (
    <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b dark:border-gray-600">
      <tr className="hidden">
        <th>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed rounded-t-md inset-0 bg-opacity-25" scope="col"></div>
              </Transition.Child>
              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto py-4 pb-6 shadow-xl  bg-white  dark:bg-gray-900">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900  dark:text-gray-50">
                        Filters
                      </h2>
                      <button
                        type="button"
                        className={`cursor-pointer flex gap-0.5 justify-center items-center overflow-hidden text-sm font-medium transition rounded-full py-1 px-2 text-white bg-zinc-700 hover:bg-zinc-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`}
                        onClick={() => setOpen(false)}>
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <form className="mt-4">
                      {labels.map((label, index) =>
                        !isValidElement(data[0]?.[selectedData?.[keys?.[index]]]) ? (
                          <Popover
                            as="div"
                            key={label}
                            className="border-t border-gray-200 dark:border-gray-700 px-4 py-6 ">
                            {({ open }) =>
                              !isValidElement(data[0]?.[keys?.[index]]) ? (
                                <FilterBoxResponsive
                                  label={label}
                                  filterKey={keys?.[index]}
                                  selectedData={selectedData?.[keys?.[index]]}
                                  setSelectedData={(arr) => setSelectedData(keys?.[index], arr)}
                                  key={index}
                                  data={data}
                                  open={open}
                                  sortedData={sortedData}
                                  handleSortedData={handleSortedData}
                                />
                              ) : (
                                <>{label}</>
                              )
                            }
                          </Popover>
                        ) : null
                      )}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </th>
      </tr>
      <tr className="sm:hidden">
        {labels.map((o, index) => {
          return (
            <th
              key={`labels_${index}`}
              scope="col"
              className="px-6 py-3 pl-2 border-r dark:border-gray-800 uppercase">
              {o}
            </th>
          );
        })}
        {!hideEdit && (
          <th scope="col" className="relative py-3 pl-3 sm:pr-2 flex flex-row-reverse pr-4">
            <span className="sr-only">Filter</span>
            <button
              type="button"
              className={`cursor-pointer flex gap-0.5 justify-center items-center overflow-hidden text-sm font-medium transition rounded-full py-1 px-2 text-white bg-zinc-700 hover:bg-zinc-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`}
              onClick={() => setOpen(true)}>
              <AdjustmentsVerticalIcon className="w-5 h-5" />
            </button>
          </th>
        )}
      </tr>

      <Popover.Group as="tr" className="hidden sm:table-row relative">
        {labels.map((label, index) => (
          <FilterBox
            label={label}
            filterKey={keys?.[index]}
            selectedData={selectedData?.[keys?.[index]]}
            setSelectedData={(arr) => setSelectedData(keys?.[index], arr)}
            key={index}
            data={data}
            sortedData={sortedData}
            handleSortedData={handleSortedData}
            totalElement={totalElement}
            lengthOfDataShown={lengthOfDataShown}
          />
        ))}
        {!hideEdit && <th scope="col" className="py-3 pr-6 text-right"></th>}
      </Popover.Group>
    </thead>
  );
};

export default FilterBoxLayout;
