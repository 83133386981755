import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { InputText, Combobox } from '@components';

import queries from '@queries';

const ChantiersEdit = () => {
  const { id } = useParams();

  const { data: chantier } = useQuery(
    ['chantiers', id],
    () => queries.get(`/api/chantiers`, id, { populate: '*' }).then((res) => res.data),
    { retry: false, enabled: !(typeof id === 'undefined' || id === null) }
  );

  return (
    <EditForm
      title="Chantiers"
      description="Ce formulaire permets de modifier les informations liées au chantier, telles que son nom, sa territorialité ou son statut.">
      <input type="hidden" name="id" defaultValue={chantier?.id} />
      <InputText
        name="idReport"
        label="Id du chantier *"
        type="number"
        defaultValue={chantier?.idReport}
        required
      />
      <InputText name="Nom" label="Nom du chantier *" defaultValue={chantier?.Nom} required />
      <Combobox
        name="Territorialite"
        label="Territorialité *"
        data={['FR', 'MC', 'FR/ES']}
        valueKey={(p) => p}
        displayValue={(p) => p}
        displayOptions={(p) => p}
        filter={(p, query) => p.includes(query)}
        defaultValue={chantier?.Territorialite}
        required
      />
      <InputText name="Debut" type="date" label="Date de début" defaultValue={chantier?.Debut} />
      <InputText name="Fin" type="date" label="Date de fin" defaultValue={chantier?.Fin} />
    </EditForm>
  );
};

export default ChantiersEdit;
