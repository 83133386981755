export default {
  DEV: 'http://localhost:1337',
  STAGING: 'https://cubeco-staging-back.herokuapp.com',
  PRODUCTION: 'https://cubeco-prod-back.herokuapp.com'
  // eslint-disable-next-line no-undef
}[process.env.REACT_APP_ENV || 'DEV'];

export const baseFrontUrl = {
  DEV: 'http://localhost:3000',
  STAGING: 'https://cubeco-staging-front.herokuapp.com',
  PRODUCTION: 'https://cubeco.thoody.com'
  // eslint-disable-next-line no-undef
}[process.env.REACT_APP_ENV || 'DEV'];
