import { useEffect, useRef } from 'react';

const CheckBox = (props) => {
  const { label = '', form = undefined, name = '', indeterminateVal = false, ...rest } = props;

  const checkboxRef = useRef();
  // Compute if the All button is indeterminate or not
  useEffect(() => {
    if (checkboxRef?.current) checkboxRef.current.indeterminate = indeterminateVal;
  });

  return (
    <div className="w-full flex items-center max-h-10 cursor-pointer px-1">
      <input name={name} type="hidden" value={false} form={form} />
      <input
        className={`cursor-pointer h-3 w-3 rounded border-gray-300 text-teal-600 focus:ring-teal-500 dark:bg-gray-700 dark:border-gray-500 dark:focus:ring-offset-gray-800`}
        ref={checkboxRef}
        type="checkbox"
        value={true}
        name={name}
        id={name}
        form={form}
        {...rest}
      />
      <label
        htmlFor={name}
        className="ml-3 cursor-pointer whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
