import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

const FilterNotification = (props) => {
  const { label = '', onClick = () => {} } = props;
  return (
    <span
      className={`rounded mr-1 bg-gray-200 dark:bg-zinc-800 dark:border dark:border-gray-600 py-0.5 pl-1.5 text-xs font-semibold tabular-nums text-gray-700 dark:text-white flex gap-1.5`}>
      {label}
      <span onClick={onClick}>
        <XMarkIcon className="h-4 w-4" />
      </span>
    </span>
  );
};

export default FilterNotification;
