import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { EditForm } from '@custom';
import { InputText } from '@components';

import queries from '@queries';

const FeuillesEdit = () => {
  const { id } = useParams();

  const { data: feuille } = useQuery(
    ['sheets', id],
    () => queries.get(`/api/sheets`, id, { populate: '*' }).then((res) => res.data),
    { retry: false, enabled: !(typeof id === 'undefined' || id === null) }
  );

  return (
    <EditForm
      title="Feuilles"
      description="Ce formulaire permets de modifier les informations liées au feuille, telles que son nom, sa territorialité ou son statut.">
      <input type="hidden" name="id" defaultValue={feuille?.id} />
      <InputText name="Nom" label="Nom *" defaultValue={feuille?.Nom} required />
      <InputText name="Debut" label="Début *" type="date" defaultValue={feuille?.Debut} required />
      <InputText name="Fin" label="Fin *" type="date" defaultValue={feuille?.Fin} required />
    </EditForm>
  );
};

export default FeuillesEdit;
