import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import queries from '@queries';
import baseUrl from '@root/config';
import { Table, Modal, Button, Combobox, InputText, Loading } from '@components';
import moment from 'moment';
import { ExportModal } from '../custom';
import { motion } from 'framer-motion';

const actualWeek = moment(new Date()).format('w');

const agences = ['Boss Interim', 'Cubeco', 'MI', 'ARC', 'MCI', 'FBI', 'MGTT', 'MBRI'];

const ExportPresence = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [month, setMonth] = useState(moment().format('YYYY-MM'));
  const [employeeId, setEmployeeId] = useState(null);
  const [agence, setAgence] = useState(null);

  const endOfMonth = moment(month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
  const startOfMonth = moment(month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');

  const { data: employeesRaw, isLoading: isLoadingEmployee } = useQuery(['employees', month], () =>
    queries
      .getAll(`/api/employees`, {
        filters: {
          $and: [
            { $or: [{ Debut: { $lte: endOfMonth } }, { Debut: { $null: true } }] },
            { $or: [{ Fin: { $gte: startOfMonth } }, { Fin: { $null: true } }] }
          ]
        }
      })
      .then((res) => res.data)
  );

  const employees = isLoadingEmployee ? [] : employeesRaw;

  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
        className="inline-flex gap-0.5 justify-center text-sm font-medium transition rounded-full  dark:bg-gray-900 py-1 px-3 focus:ring-teal-400/20 bg-gray-700 hover:bg-gray-900 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300">
        Exporter les présences
      </Button>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="border-b dark:border-gray-500 p-2 dark:text-gray-200 text-xl ">
          <h1> Exporter les fiches de présences</h1>
        </div>
        <div className="p-2">
          <InputText
            label="Choisissez le mois concerné :"
            type="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />
          <Combobox
            label="Employé concerné :"
            className="mt-2"
            data={employees}
            value={employeeId}
            setValue={setEmployeeId}
            displayValue={(p) => (p?.Nom ? `${p?.Nom} ${p?.Prenom}` : '')}
            displayOptions={(p) => (p?.Nom ? `${p?.Nom} ${p?.Prenom}` : '')}
            filter={(p, query) =>
              `${p?.Nom} ${p?.Prenom}`?.toLowerCase()?.includes(query?.toLowerCase())
            }
            isControlled
          />
        </div>
        <div className="p-2 text-right">
          <Button
            onClick={() =>
              window.open(
                `${baseUrl}/api/report/presence?month=${month}&employeeId=${employeeId?.id}&isPdf=1`,
                '_blank'
              )
            }>
            Télécharger
          </Button>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex items-center px-5" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">OU</span>
          </div>
        </div>
        <div className="p-2">
          <InputText
            label="Choisissez le mois concerné :"
            type="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />
          <Combobox
            label="Agence concernée :"
            className="mt-2"
            data={agences}
            value={agence}
            setValue={setAgence}
            valueKey={(d) => d}
            displayValue={(p) => p}
            displayOptions={(p) => p}
            filter={(p, query) => p?.toLowerCase()?.includes(query?.toLowerCase())}
            isControlled
          />
        </div>
        <div className="p-2 text-right">
          <Button
            onClick={() =>
              window.open(
                `${baseUrl}/api/report/interim?month=${month}&agence=${agence}&isPdf=1`,
                '_blank'
              )
            }>
            Télécharger
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const Home = () => {
  const [idSheetEmployee, setIdSheetEmployee] = useState(false);
  const [idSheetChantier, setIdSheetChantier] = useState(false);

  const { data: currentSheet, isLoading: currentSheetLoading } = useQuery(
    ['sheets', 'current'],
    () =>
      queries
        .getAll(`/api/sheets`, {
          filters: { Debut: { $eq: moment().startOf('isoWeek').format('YYYY-MM-DD') } }
        })
        .then((res) => res?.data?.[0])
  );

  const { data: chantiers, isLoading: chantiersLoading } = useQuery(
    ['chantiers', 'current'],
    () =>
      queries
        .getAll(`/api/chantiers`, {
          filters: {
            $and: [
              { $or: [{ Debut: { $lte: currentSheet?.Fin } }, { Debut: { $null: true } }] },
              { $or: [{ Fin: { $gte: currentSheet?.Debut } }, { Fin: { $null: true } }] }
            ]
          }
        })
        .then((res) => res.data),
    { enabled: !!currentSheet?.id }
  );

  const { data: employees, isLoading: employeesLoading } = useQuery(
    ['employee', 'current'],
    () =>
      queries
        .getAll(`/api/employees`, {
          filters: {
            $and: [
              { $or: [{ Debut: { $lte: currentSheet?.Fin } }, { Debut: { $null: true } }] },
              { $or: [{ Fin: { $gte: currentSheet?.Debut } }, { Fin: { $null: true } }] }
            ]
          }
        })
        .then((res) => res.data),
    { enabled: !!currentSheet?.id }
  );

  const { data: heures, isLoading: heuresLoading } = useQuery(
    ['heures', 'current'],
    () =>
      queries
        .getAll(`/api/heures`, { populate: '*', filters: { feuille: { id: currentSheet?.id } } })
        .then((res) => res.data),
    { enabled: !!currentSheet?.id }
  );

  const { data: sheets } = useQuery(['sheets'], () =>
    queries.getAll(`/api/sheets`, { sort: ['Debut:desc'] }).then((res) => res?.data)
  );

  const stats = [
    {
      id: 1,
      name: 'Heures',
      value: heures?.reduce((a, b) => a + b?.heure, 0),
      modal: <ExportPresence employees={employees} />
    },
    {
      id: 2,
      name: 'Chantier',
      value: chantiers?.length,
      modal: (
        <ExportModal
          title="Exporter les chantiers"
          url={`${baseUrl}/api/report/chantiers?s=${idSheetChantier}`}
          data={sheets}
          idSheet={idSheetChantier}
          setIdSheet={setIdSheetChantier}
        />
      )
    },
    {
      id: 3,
      name: 'Employés',
      value: employees?.length,
      modal: (
        <ExportModal
          title="Exporter les salariés"
          url={`${baseUrl}/api/report/salaries?s=${idSheetEmployee}`}
          data={sheets}
          idSheet={idSheetEmployee}
          setIdSheet={setIdSheetEmployee}
        />
      )
    }
  ];

  if (chantiersLoading && employeesLoading && heuresLoading && currentSheetLoading)
    return (
      <Loading
        src={
          'https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/preview/90-ring-with-bg-black-36.svg'
        }
        className={'animate-spin h-8 invert-0 dark:invert'}
      />
    );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}>
      <div className="min-h-[80vh] w-full flex items-center justify-center flex-col gap-8 dark:text-white">
        <div className=" py-2 w-full">
          <div className="mx-auto px-6 mb-10">
            <div className="text-gray-500 dark:text-gray-200 flex justify-center items-center h-[150px] md:text-6xl sm:text-4xl text-2xl">
              Semaine {actualWeek}
            </div>
            <dl className="flex gap-y-8 text-center flex-wrap">
              {stats?.map((stat) => (
                <div
                  key={stat.id}
                  className="m-auto flex max-w-xs justify-between gap-y-4 bg-gray-50 dark:bg-gray-800 dark:border-gray-600 border shadow-sm rounded-md px-4 py-2 w-full">
                  <div className="flex flex-col items-start">
                    <dt className="text-base leading-7 text-gray-500 dark:text-gray-400">
                      {stat.name} :
                    </dt>
                    <dd className="text-3xl font-semibold tracking-tight text-gray-800 dark:text-white sm:text-5xl">
                      {stat.value}
                    </dd>
                  </div>
                  <div className="self-end">{stat.modal}</div>
                </div>
              ))}
            </dl>
          </div>
          <div className="w-full">
            {heures?.length > 0 ? (
              <Table
                data={heures?.map((o) => ({
                  id: o?.id,
                  sheet: o?.feuille?.Nom,
                  chantier: o?.chantier?.Nom || 'Chantier non défini',
                  jour: o?.jour,
                  employee: `${o?.employee?.Nom} ${o?.employee?.Prenom}`,
                  heures: o?.heure,
                  comment: o?.comment ? o?.comment : 'Pas de commentaire'
                }))}
                hideEdit
                labels={['Id', 'Feuilles', 'Jour', 'Employé', 'Heure', 'Commentaire']}
                className={'mt-2 sm:mt-5'}
                groupBy={'chantier'}
                paginate={10}
                isLoading={heuresLoading}
              />
            ) : (
              <div className="mt-4 text-gray-500 flex justify-center items-center h-[150px] text-lg">
                Aucune heure effectuée cette semaine.
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
