import { Section } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table } from '@components';
import moment from 'moment';

function ChantiersPage() {
  const navigate = useNavigate();

  const { data: tableData, isLoading: isLoading } = useQuery(['chantiers'], () =>
    queries.getAll(`/api/chantiers`, { populate: '*' }).then((res) => res.data)
  );

  const sortedData = tableData?.sort((a, b) => a.id - b.id).reverse();

  return (
    <Section
      title="Chantiers"
      description="Liste des chantiers"
      onClick={() => navigate('/edit/chantier')}>
      <Table
        data={sortedData?.map((o) => ({
          id: o?.id,
          idReport: o?.idReport,
          name: o?.Nom,
          heure: o?.heures?.reduce((a, b) => a + b?.heure, 0),
          territorialite: o?.Territorialite,
          debut: o?.Debut ? moment(o?.Debut).format('DD/MM/YYYY') : '',
          fin: o?.Fin ? moment(o?.Fin).format('DD/MM/YYYY') : ''
        }))}
        onClick={(_, o) => navigate(`/edit/chantier/${o?.id}`)}
        labels={[
          'Id',
          'Id Report',
          'Nom',
          'Heures',
          'Territorialite',
          'Date de début',
          'Date de fin'
        ]}
        paginate={25}
        className={'mt-2 sm:mt-5'}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default ChantiersPage;
