import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { InputText, Combobox } from '@components';

import queries from '@queries';

const EmployeesEdit = () => {
  const { id } = useParams();

  const capitalize = (val) => val[0].toUpperCase() + val.slice(1, val.length).toLowerCase();

  const { data: employee } = useQuery(
    ['employees', id],
    () => queries.get(`/api/employees`, id, { populate: '*' }).then((res) => res.data),
    { retry: false, enabled: !(typeof id === 'undefined' || id === null) }
  );

  return (
    <EditForm
      title="Employés"
      description="Ce formulaire permets de modifier les informations liées a un employé, telles que son nom, son agence  ou son nombre d'heure.">
      <input type="hidden" name="id" defaultValue={employee?.id} />
      <InputText
        name="idReport"
        label="Id du rapport *"
        type="number"
        defaultValue={employee?.idReport}
        required
      />
      <InputText
        name="Prenom"
        label="Prénom de l'employé *"
        type="text"
        defaultValue={employee?.Prenom && capitalize(employee?.Prenom)}
        required
      />
      <InputText
        name="Nom"
        label="Nom de l'employé *"
        defaultValue={employee?.Nom && capitalize(employee?.Nom)}
        required
      />
      <Combobox
        name="Agence"
        label="Agence *"
        data={['Boss Interim', 'Cubeco', 'MI', 'ARC', 'MCI', 'FBI', 'MGTT', 'MBRI', 'ELITE']}
        defaultValue={employee?.Agence}
        displayValue={(p) => p}
        displayOptions={(p) => p}
        valueKey={(p) => p}
        filter={(p, query) => p?.includes(query)}
        required
      />
      <InputText name="Debut" type="date" label="Date de début" defaultValue={employee?.Debut} />
      <InputText name="Fin" type="date" label="Date de fin" defaultValue={employee?.Fin} />
    </EditForm>
  );
};

export default EmployeesEdit;
