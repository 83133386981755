import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DarkModeProvider } from '@components';
import { Panel } from '@custom';

import { AnimatePresence } from 'framer-motion';
import {
  Auth,
  ChantiersPage,
  ChantiersEdit,
  EmployeesPage,
  EmployeesEdit,
  HeuresPage,
  HeuresEdit,
  FeuillesPage,
  FeuillesEdit,
  Home
} from '@pages';

const links = [
  {
    pageName: 'home',
    name: 'Accueil',
    renderPage: <Home />
  },
  {
    pageName: 'chantier',
    name: 'Chantiers',
    renderPage: <ChantiersPage />,
    renderEdit: <ChantiersEdit />
  },
  {
    pageName: 'employee',
    name: 'Employés',
    renderPage: <EmployeesPage />,
    renderEdit: <EmployeesEdit />
  },
  {
    pageName: 'heure',
    name: 'Heures',
    renderPage: <HeuresPage />,
    renderEdit: <HeuresEdit />
  },
  {
    pageName: 'sheet',
    name: 'Feuilles',
    renderPage: <FeuillesPage />,
    renderEdit: <FeuillesEdit />
  }
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

const router = createBrowserRouter([
  { path: '/', loader: async () => redirect('/chantier') },
  {
    path: '/:pageName',
    element: (
      <Auth>
        <Panel links={links.map((o) => ({ ...o, render: o?.renderPage }))} />
      </Auth>
    )
  },
  {
    path: '/:pageName/:employeeId',
    element: (
      <Auth>
        <Panel links={links.map((o) => ({ ...o, render: o?.renderPage }))} />
      </Auth>
    )
  },
  {
    path: '/edit/:pageName/:id?',
    element: (
      <Auth>
        <Panel links={links.map((o) => ({ ...o, render: o?.renderEdit }))} />
      </Auth>
    )
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkModeProvider>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AnimatePresence mode="wait">
          <RouterProvider router={router} />
        </AnimatePresence>
      </QueryClientProvider>
    </React.StrictMode>
  </DarkModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
