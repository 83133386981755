import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';
import FilterLayout from './layout/FilterLayout';
import TableLayout from './layout/TableLayout';
import Pagination from './components/Pagination';
import SortOptions from './components/SortOptions';
import PaginationSelection from './components/PaginationSelection';
import TableLoader from './components/TableLoader';

const capitalize = (str) =>
  str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');

const addGroupBy = (data, groupBy, groupBySorted) => {
  // Order and get all the key
  let dataSorted = _.sortBy(
    data.map((o) => ({ ...o, [groupBy]: ReactDOMServer.renderToString(o?.[groupBy]) })),
    [groupBy]
  );
  const dataOrdered = groupBySorted === 'ASC' ? dataSorted : dataSorted.reverse();

  const keys = dataOrdered.map((o) => o?.[groupBy]);
  // Get one of each and find their index
  const uniqKeys = _.uniq(keys);
  const indexKeys = uniqKeys.map((key) => dataOrdered.findIndex((o) => o?.[groupBy] === key));

  // Reverse key and index to start from the end
  const reverseIndexKeys = indexKeys.reverse();
  const reverseUniqKeys = uniqKeys.reverse();

  // Insert element at the right place
  for (let i = 0; i < reverseIndexKeys.length; i++) {
    dataOrdered.splice(reverseIndexKeys[i], 0, {
      _title: reverseUniqKeys[i].length > 0 ? reverseUniqKeys[i] : 'Undefined'
    });
  }

  // Remove the key
  return dataSorted.map((o) => _.omit(o, groupBy));
};

const Table = (props) => {
  const {
    data = [],
    labels = Object.keys(data?.[0] || []).map((o) => capitalize(o)),
    onClick = () => {},
    className = '',
    hideEdit = false,
    classNameColumn = '',
    groupBy = '',
    groupBySorted = 'ASC',
    highlight = '',
    paginate = null,
    paginateRange = 5,
    isLoading = false
  } = props;

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [sortedData, setSortedData] = useState({});
  const [pagination, setPagination] = useState(paginate);

  // Handling the update of selected data within FilterBoxes
  const updateSelectedData = (key, arr) => {
    setSelectedData({ ...selectedData, [key]: arr });
  };

  // Handling the update of sorted data within FilterBoxes
  const updateSortedData = (key, order) => {
    order !== ''
      ? setSortedData({ ...sortedData, [key]: order })
      : setSortedData(_.omit(sortedData, key));
  };

  // Sort value ASC or DESC from the filtered key
  const sortedOption = _.orderBy(data, Object.keys(sortedData), Object.values(sortedData));

  // Cumulate filter of the different data
  const filteredData = sortedOption?.filter((a) =>
    Object.entries(selectedData).every(
      ([key, value]) => value.includes(a[key]) || value.length === 0
    )
  );

  // Transform label and data if grouped by
  const dataGrouped =
    groupBy.length > 0 ? addGroupBy(filteredData, groupBy, groupBySorted) : filteredData;

  const labelsGrouped =
    groupBy.length > 0 ? labels.filter((l) => l != capitalize(groupBy)) : labels;

  // If the number of pages is reduced because of a filter to an inferior number than the current page, ex (maxPage = 2, currentPage = 5) then take the lower number
  const maxPagedAfterFilter = Math.ceil(dataGrouped.length / pagination);
  const currentPageGrouped = Math.min(maxPagedAfterFilter, currentPage);

  // Cut array for pagination
  const currentData = pagination
    ? dataGrouped.slice((currentPageGrouped - 1) * pagination, currentPageGrouped * pagination)
    : dataGrouped;

  return isLoading ? (
    <TableLoader />
  ) : (
    <>
      <TableLayout className={className}>
        <FilterLayout
          labels={labelsGrouped}
          hideEdit={hideEdit}
          data={data}
          pagination={pagination}
          setPagination={setPagination}
          keys={Object.keys(data?.[0] || [])?.filter((d) => d != groupBy)}
          selectedData={selectedData}
          setSelectedData={updateSelectedData}
          updateSortedData={updateSortedData}
          sortedData={sortedData}
          totalElement={filteredData.length}
          lengthOfDataShown={currentData.length}
        />

        <tbody>
          {currentData.map((o, index) =>
            o?._title ? (
              <tr
                key={`row-${index}`}
                className={
                  currentData.length !== index - 1 &&
                  `border-b dark:bg-gray-900 dark:border-gray-700`
                }>
                <th
                  colSpan={labelsGrouped.length + (hideEdit ? 0 : 1)}
                  scope="colgroup"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  dangerouslySetInnerHTML={{ __html: o?._title ? o?._title : '' }}></th>
              </tr>
            ) : (
              <tr
                key={`row-${index}`}
                className={
                  index % 2 === 0
                    ? `bg-white dark:bg-gray-900 ${
                        index + 1 === currentData.length
                          ? 'rounded-b-md overflow-hidden '
                          : 'border-b dark:border-gray-700 '
                      } ${highlight && 'hover:' + highlight + ' dark:hover:' + highlight}`
                    : `bg-gray-50 dark:bg-gray-800 ${
                        index + 1 === currentData.length
                          ? 'rounded-b-md overflow-hidden '
                          : 'border-b dark:border-gray-700'
                      } ${highlight && 'hover:' + highlight + ' dark:hover:' + highlight}`
                }>
                {Object.keys(o).map((key, keyIndex) => (
                  <td
                    key={`row-${index}-${keyIndex}`}
                    className={
                      keyIndex == 0
                        ? `cursor-default border-r dark:border-gray-700 whitespace-nowrap py-4 pl-4 pr-3 text-gray-500 dark:text-gray-100 text-sm sm:pl-6 ${classNameColumn}`
                        : `cursor-default border-r dark:border-gray-700 whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300 ${classNameColumn}`
                    }>
                    {o?.[key]}
                  </td>
                ))}
                {!hideEdit && (
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a
                      href="#"
                      className={`text-teal-600 dark:text-teal-400 hover:text-teal-900 dark:hover:text-teal-900`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClick(index, o);
                      }}>
                      Modifier
                    </a>
                  </td>
                )}
              </tr>
            )
          )}
        </tbody>
      </TableLayout>
      {paginate && (
        <div className="grid grid-rows-3 gap-3 sm:grid-cols-3 sm:grid-rows-1  sm:gap-0 items-center py-6">
          <SortOptions
            setPagination={setPagination}
            pagination={pagination}
            totalElement={data.length + 1}
          />
          <Pagination
            maxPage={Math.ceil(dataGrouped.length / pagination)}
            range={paginateRange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <PaginationSelection
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            maxPage={Math.ceil(dataGrouped.length / pagination)}
          />
        </div>
      )}
    </>
  );
};

export default Table;
