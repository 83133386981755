import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { InputText, InputTextarea, Combobox } from '@components';

import queries from '@queries';

const HeuresEdit = () => {
  const { id } = useParams();

  const { data: heure } = useQuery(
    ['heures', id],
    () => queries.get(`/api/heures`, id, { populate: '*' }).then((res) => res.data),
    { retry: false, enabled: !(typeof id === 'undefined' || id === null) }
  );

  const { data: chantiers } = useQuery(['chantiers'], () =>
    queries.getAll(`/api/chantiers`, { populate: '*' }).then((res) => res.data)
  );
  const { data: employees } = useQuery(['employee'], () =>
    queries.getAll(`/api/employees`, { populate: '*' }).then((res) => res.data)
  );
  const { data: sheets } = useQuery(['sheets'], () =>
    queries.getAll(`/api/sheets`, { populate: '*' }).then((res) => res.data)
  );

  return (
    <EditForm
      title="Heures"
      description="Ce formulaire permets de modifier les informations liées au heure, telles que le jour, l'heure ou encore écrire un commentaire.">
      <input type="hidden" name="id" defaultValue={heure?.id} />
      <InputText
        name="jour"
        label="Jour travaillé *"
        type="date"
        defaultValue={heure?.jour}
        required
      />
      <InputText
        name="heure"
        label="Nombre d'heure effectué"
        type="number"
        defaultValue={heure?.heure}
      />
      <InputTextarea
        name="comment"
        label="Commentaire"
        type="number"
        defaultValue={heure?.comment}
      />
      <Combobox
        name="chantier"
        label="Chantier *"
        data={chantiers}
        defaultValue={heure?.chantier}
        displayValue={(p) => p?.Nom}
        displayOptions={(p) => p?.Nom}
        filter={(p, query) => p?.Nom?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace
        required
      />
      <Combobox
        name="employee"
        label="Employé *"
        data={employees}
        defaultValue={heure?.employee}
        displayValue={(p) => p?.Nom}
        displayOptions={(p) => p?.Nom}
        filter={(p, query) => p?.Nom?.toLowerCase()?.includes(query?.toLowerCase())}
        allowSpace={true}
        required
      />
      <Combobox
        name="feuille"
        label="Feuille *"
        data={sheets}
        defaultValue={heure?.feuille}
        displayValue={(p) => p?.Nom}
        displayOptions={(p) => p?.Nom}
        filter={(p, query) => p?.Nom?.toString().includes(query)}
        required
      />
    </EditForm>
  );
};

export default HeuresEdit;
