import React from 'react';

const TableLayout = (props) => {
  const { className, children = null } = props;
  return (
    <div className={'mt-8  z-0 flex flex-col ' + className}>
      <div className="relative shadow-md overflow-x-auto rounded-md dark:shadow-gray-700 dark:shadow-sm">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          {children}
        </table>
      </div>
    </div>
  );
};
export default TableLayout;
